import React, { Component } from "react";

import axios from "axios";
import { min, max } from "d3-array";
import { scaleLinear } from "d3-scale";
import { select } from "d3-selection";
import { line, curveLinearClosed } from "d3-shape";
import { ContextMenu, MenuItem, ContextMenuTrigger } from "react-contextmenu";
import UserController from "./UserController";
import { RiDeleteBin6Line } from "react-icons/ri";
import Translation from './Translation';

import "./FarmBrowser.css";

class FarmIcon extends Component {
  constructor(props) {
    super(props);
    this.createFarmIcon = this.createFarmIcon.bind(this);
    this.trans = Translation.getInstance().translate;

    this.w = 0;
    this.h = 0;
  }

  componentDidMount() {
    const chart = document.getElementById(this.props.id);
    this.w = chart.clientWidth;
    this.h = chart.clientHeight;
    this.createFarmIcon();
    window.addEventListener("resize", this.createFarmIcon);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.createFarmIcon);
  }

  componentDidUpdate() {
    const chart = document.getElementById(this.props.id);
    this.w = chart.clientWidth;
    this.h = chart.clientHeight;
    this.createFarmIcon();
  }
  createFarmIcon() {
    if (this.props.farm.farm_name) {
      const chart = document.getElementById(this.props.id);
      const w = chart.clientWidth;
      const h = chart.clientHeight;

      document.getElementById(this.props.id).innerHTML = "";

      const svg = select("#" + this.props.id)
        .append("svg")
        .attr("width", w)
        .attr("height", h);

      const size = this.props.size;
      const farm_name = this.props.farm.farm_name;
      const font_size = Math.min(size / 8, size / farm_name.length*1.2);
      // Define margins
      const margin = {
        top: size / 20,
        right: size / 20,
        bottom: size / 20 + font_size,
        left: size / 20
      };

      const width = w - margin.left - margin.right;
      const height = h - margin.top - margin.bottom;

      // Get paddocks
      const paddocks = this.props.farm.paddocks;

      // Find bounds of map
      const max_easting = max(paddocks, function(p) {
        return max(p.coordinates, function(c) {
          return c.easting;
        });
      });
      const min_easting = min(paddocks, function(p) {
        return min(p.coordinates, function(c) {
          return c.easting;
        });
      });

      const max_northing = max(paddocks, function(p) {
        return max(p.coordinates, function(c) {
          return c.northing;
        });
      });
      const min_northing = min(paddocks, function(p) {
        return min(p.coordinates, function(c) {
          return c.northing;
        });
      });

      const east_range = max_easting - min_easting;
      const north_range = max_northing - min_northing;

      // Define scales
      let xScale;
      let yScale;
      const ratio = (east_range * height) / (north_range * width);
      if (ratio > 1) {
        // East range controls the scale
        xScale = scaleLinear()
          .domain([min_easting, max_easting])
          .range([width * 0.01, width * 0.99]);
        yScale = scaleLinear()
          .domain([min_northing, max_northing])
          .range([
            height / ratio + (height * (0.99 - 1 / ratio)) / 2,
            (height * (1.01 - 1 / ratio)) / 2
          ]);
      } else {
        // North range controls the scale
        xScale = scaleLinear()
          .domain([min_easting, max_easting])
          .range([
            (width * (1.01 - ratio)) / 2,
            width * ratio + (width * (0.99 - ratio)) / 2
          ]);
        yScale = scaleLinear()
          .domain([min_northing, max_northing])
          .range([height * 0.99, height * 0.01]);
      }

      // Define line function
      const drawPaddock = line()
        .x(function(d) {
          return xScale(d.easting);
        })
        .y(function(d) {
          return yScale(d.northing);
        })
        .curve(curveLinearClosed);

      // Add map group
      const map = svg.append("g");

      // Draw map
      map
        .selectAll("path")
        .data(paddocks)
        .enter()
        .append("path")
        .attr("d", function(d) {
          return drawPaddock(d.coordinates);
        })
        .attr("class", "Paddock")
        .style("pointer-events", "none")
        .attr("transform", "translate(" + margin.left + "," + margin.top + ")");

      map
        .append("text")
        .text(farm_name)
        .attr("x", w / 2)
        .attr("y", h - font_size / 2)
        .attr("class", "FarmIconTitle")
        .attr("text-anchor", "middle")
        .style("pointer-events", "none")
        .style("font-size", font_size + "px");
    }
  }
  async delete() {
    // console.log(this.props.farm)
    var confirmed = window.confirm(
      this.trans('are_you_sure_remove_farm_from_account')
    );
    if (confirmed) {
      const self = this;
      axios
        .get("https://staging.grasslandtools.ie/api/deletefarm/", {
          params: {
            farmid: this.props.farm.farm_id,
            sessionid: UserController.getSessionkey()
          }
        })
        .then(response => {
          if (response.status == 200) {
            self.props.reloadIcons();
          }
        });
    }
  }
  render() {
    return (
      <div>
        <ContextMenuTrigger id={"delete_farm_menu" + this.props.id}>
          <div
            id={this.props.id}
            onClick={this.props.enabled ? this.props.onClick : null}
            className={"FarmIcon " + (this.props.enabled ? "" : "Disabled")}
            style={{
              width: this.props.size + "px",
              height: this.props.size + "px",
              borderRadius: this.props.size / 10 + "px",
              margin: this.props.margin + "px",
            }}
          />
        </ContextMenuTrigger>
        <ContextMenu id={"delete_farm_menu" + this.props.id}>
          <MenuItem onClick={this.delete.bind(this)}>
            <RiDeleteBin6Line className="delete" />
            <span>{this.trans("delete_farm")}</span>
          </MenuItem>
        </ContextMenu>
      </div>
    );
  }
}

class HalfHeightFarmBrowser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      w: null,
      h: null,
      farms: [],
      killAsync: false,
      letter: "#",
      page: 0
    };
    this._ismounted = false;
    this.updateDimensions = this.updateDimensions.bind(this);
    this.downloadFarm = this.downloadFarm.bind(this);
    this.trans = Translation.getInstance().translate;
  }

  fitSquares(w, h, n) {
    // if (n < 3) { n = 3 }
    n = 4;
    let sx;
    let sy;

    const px = Math.ceil(Math.sqrt((n * w) / h));
    if (Math.floor((px * h) / w) * px < n) {
      sx = h / Math.ceil((px * h) / w);
    } else {
      sx = w / px;
    }

    const py = Math.ceil(Math.sqrt((n * h) / w));
    if (Math.floor((py * w) / h) * py < n) {
      sy = w / Math.ceil((py * w) / h);
    } else {
      sy = h / py;
    }

    return Math.min(Math.max(sx, sy, 290 / 0.9), 360);
  }

  componentDidMount() {
    this.updateDimensions();
    window.addEventListener("resize", this.updateDimensions);
    const self = this;
    this.getFarms = async function getFarms(fIds) {
      let farms = [];
      self.setState({ farms: [] });
      const letter = this.state.letter;
      for (const id of fIds) {
        if (self._ismounted && self.state.letter === letter) {
          this.downloadFarm(id);
        } else {
          break;
        }
      }
    };
    this._ismounted = true;
    this.getFarms(this.props.farms);
  }

  async downloadFarm(farmid) {
    const letter = this.state.letter;
    const farm = await axios.get(
      "https://staging.grasslandtools.ie/api/getfarmoverview/",
      {
        params: {
          farm_id: farmid
        }
      }
    );
    if (this._ismounted && this.state.letter === letter) {
      const farms = this.state.farms.slice();
      farms.push(farm.data);
      // console.log(farm.data)
      farms.sort(function(a, b) {
        var nameA = a.farm_name.toUpperCase(); // ignore upper and lowercase
        var nameB = b.farm_name.toUpperCase(); // ignore upper and lowercase
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }

        // names must be equal
        return 0;
      });
      this.setState({ farms: farms });
    }
  }

  componentWillUnmount() {
    this._ismounted = false;
    window.removeEventListener("resize", this.updateDimensions);
  }

  updateDimensions() {
    let div = document.getElementById("HalfHeightFarmBrowser");
    this.setState({
      w: div.clientWidth,
      h: div.clientHeight
    });
  }

  setLetter(ltr) {
    this.setState({ letter: ltr });
    axios
      .get("https://staging.grasslandtools.ie/api/getfarmsbyletter/", {
        params: {
          sessionid: UserController.getSessionkey(),
          letter: ltr
        }
      })
      .then(response => {
        this.getFarms(response.data.farms);
      });
  }

  reloadIcons() {
    this.props.getDataset(null, "Overview", null);
  }

  incrementPage(increment) {
    this.setState({ page: this.state.page + increment });
  }

  render() {
    console.log(this.props);
    const self = this;
    let farmIcons = [];
    let container_width = 0;
    let w = this.state.w;
    let h = this.state.h;
    let n = 1;
    let size = this.state.size;
    let margin = w*0.005;
    let iconSize = w*0.14;
    if (this.state.farms && this.state.farms.length > 0) {
      let farms = this.state.farms;
      n = farms.length;
      size = Math.min(this.fitSquares(w, h, n), Math.max(w / 2.5, h / 2.3));
      // farms = Array.from({ length: 16 }).map(x => farms[0]);
      farmIcons = farms.map(function(farm, i) {
        return (
          <FarmIcon
            key={Math.random()}
            id={"farmicon" + i}
            farm={farm}
            size={iconSize}
            margin={margin}
            onClick={self.props.getDataset.bind(self, farm.farm_id, "Toolset")}
            reloadIcons={self.reloadIcons.bind(self)}
            enabled={i < self.props.maxFarms}
          />
        );
      });
      container_width = Math.min(Math.floor(w / size) * size, size * n);
    }

    let firstPage = this.state.page === 0;
    let lastPage =
      this.state.page === Math.floor((farmIcons.length - 1) / 6) ||
      farmIcons.length < 6;

    farmIcons.unshift(
      <div
        key={-1}
        className="FarmIcon"
        onClick={this.props.setDisplay.bind(this, "add_farm")}
        style={{
          width: iconSize,
          height: iconSize,
          borderRadius: iconSize/10,
          margin: margin,
          cursor: "pointer",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-evenly",
          alignItems: "center"
        }}
      >
        <h1
          style={{
            fontSize: iconSize/3
          }}
        >
          +
        </h1>
        <h1
          style={{
            fontSize: iconSize / 7
          }}
        >
          {this.trans("add_farm")}
        </h1>
      </div>
    );
    const displayedIcons = farmIcons.slice(
      this.state.page * 6,
      this.state.page * 6 + 6
    );
    return (
      <div id="HalfHeightFarmBrowser" className="HomePageFarmBrowser">
        <div className="BrowserIconContainer">
          <div
            className="FarmBrowserNav"
            style={{
              opacity: firstPage ? 0 : 1,
              pointerEvents: firstPage ? "none" : "all"
            }}
            onClick={this.incrementPage.bind(this, -1)}
          >
            {"<"}
          </div>
          <div className="BrowserIconRow">{displayedIcons}</div>
          <div
            className="FarmBrowserNav"
            style={{
              opacity: lastPage ? 0 : 1,
              pointerEvents: lastPage ? "none" : "all"
            }}
            onClick={this.incrementPage.bind(this, +1)}
          >
            {">"}
          </div>
        </div>
      </div>
    );
  }
}

export default HalfHeightFarmBrowser;
