import React, { Component } from 'react';
import './Dashboard.css';
import FarmMap from './FarmMap';
import GrassWedge from './GrassWedge';
import PaddockHistory from './PaddockHistory';
import MetricsPanel from './MetricsPanel';
import CoverSettings from './CoverSettings';
import PaddockStatus from './PaddockStatus';
import PaddockBlocks from './PaddockBlocks';
import PaddockCovers from './PaddockCovers';
import PaddockGrazing from './PaddockGrazing';
import PaddockSilage from './PaddockSilage';
import PaddockReseed from './PaddockReseed';
import PaddockFertiliser from './PaddockFertiliser';
import OutputsPanel from './OutputsPanel';
import Calendar from './Calendar';
import Translation from './Translation';

import Formulae from './Formulae';

import axios from 'axios';
import { min, max } from 'd3-array';
import { axisLeft, axisBottom } from 'd3-axis';
import { scaleLinear, scaleTime } from 'd3-scale';
import { select } from 'd3-selection';
import { arc, line, area, curveLinearClosed, curveMonotoneX } from 'd3-shape';
import { timeFormat } from 'd3-time-format';

import moment from 'moment-with-locales-es6';

moment.locale(Translation.getInstance().langCode());
class PaddockSummary extends Component {
  constructor(props) {
    super(props);
    this.createPaddockSummary = this.createPaddockSummary.bind(this);

    this.w = 0;
    this.h = 0;
    this.trans = Translation.getInstance().translate;
  }

  componentDidMount() {
    const chart = document.getElementById('InfoGraphic');
    this.w = chart.clientWidth;
    this.h = chart.clientHeight;
    this.createPaddockSummary();
    window.addEventListener('resize', this.createPaddockSummary);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.createPaddockSummary);
  }

  shouldComponentUpdate(nextProps, nextState) {
    const chart = document.getElementById('InfoGraphic');
    if (this.w !== chart.clientWidth || this.h !== chart.clientHeight) {
      return true;
    }
    if (this.props.paddock !== nextProps.paddock) {
      return true;
    }
    if (this.props.farm !== nextProps.farm) {
      return true;
    }
    return false;
  }

  componentDidUpdate() {
    const chart = document.getElementById('InfoGraphic');
    this.w = chart.clientWidth;
    this.h = chart.clientHeight;
    this.createPaddockSummary();
  }
  createPaddockSummary() {
    const self = this;
    const chart = document.getElementById('InfoGraphic');
    const w = chart.clientWidth;
    const h = chart.clientHeight;

    document.getElementById('InfoGraphic').innerHTML = '';

    const svg = select('#InfoGraphic')
      .append('svg')
      .attr('width', w)
      .attr('height', h);

    const paddock = this.props.paddock;
    const farm = this.props.farm;
    const cover = this.props.cover;
    let survey = cover
      ? cover.padCovers
          .slice()
          .reverse()
          .find(c => c.paddock_id === paddock.id)
      : {};
    let maxYTDGrowth = cover ? max(cover.padCovers, c => c.ytdGrowth) : 0;
    let minYTDGrowth = cover ? min(cover.padCovers, c => c.ytdGrowth) : 0;
    if (!survey) {
      const allCovers = farm.covers.slice().reverse();
      for (let i = 0; i < allCovers.length; i++) {
        survey = allCovers[i].padCovers
          .slice()
          .reverse()
          .find(c => c.paddock_id === paddock.id);
        if (survey) {
          maxYTDGrowth = max(allCovers[i].padCovers, c => c.ytdGrowth);
          minYTDGrowth = min(allCovers[i].padCovers, c => c.ytdGrowth);
          break;
        }
      }
      if (!survey) {
        survey = {
          paddock_id: paddock.id,
          ytdGrowth: 0,
          rotations: 0
        };
      }
    }

    // Define margins
    const margin = {
      top: 10,
      right: 10,
      bottom: 10,
      left: 10
    };

    // Find size of drawing region
    const width = w - margin.left - margin.right;
    const height = h - margin.top - margin.bottom;

    const g = svg
      .append('g')
      .attr('transform', 'translate(' + margin.left + ',' + margin.top + ')');

    const xscale = scaleLinear()
      .domain([minYTDGrowth, maxYTDGrowth])
      .range([width / 3, width]);

    g
      .append('rect')
      .attr('x', 0)
      .attr('y', height * 0.9)
      .attr('width', xscale(maxYTDGrowth))
      .attr('height', height / 10)
      .attr('class', 'YTD')

    g
      .append('rect')
      .attr('x', 0)
      .attr('y', height * 0.78)
      .attr('width', xscale(survey.ytdGrowth))
      .attr('height', height / 10)
      .attr('class', 'YTD selected')

    g
      .append('rect')
      .attr('x', 0)
      .attr('y', height * 0.66)
      .attr('width', xscale(minYTDGrowth))
      .attr('height', height / 10)
      .attr('class', 'YTD')

    const position = Math.min(width / 4, height / 3);
    const rotSegment = arc()
      .innerRadius(0)
      .outerRadius(position);
    const tau = 2 * Math.PI;

    const max_rotations = 12;

    for (let i = 0; i < max_rotations; i++) {
      g
        .append('path')
        .attr(
          'transform',
          'translate(' + (width - position) + ',' + height / 3 + ')'
        )
        .datum({
          startAngle: (max_rotations - 1 - i) * tau / max_rotations,
          endAngle: (max_rotations - i) * tau / max_rotations
        })
        .attr('d', rotSegment)
        .attr('class', function() {
          if (survey.rotations > i) {
            return 'Rotations complete';
          } else return 'Rotations';
        });
    }

    const fontSize = Math.min(
      width / 2 * 0.18,
      (height * 2 / 3 - 30) * 0.5 - 3,
      20
    );

    const pos = 40 - margin.top;
    g
      .append('text')
      .text(self.trans('year_to_date'))
      .attr('x', 0)
      .attr('y', pos)
      .attr('class', 'ChartHeading')
      .style('font-size', fontSize + 'px')
      .attr('text-anchor', 'start');
    g
      .append('text')
      .text(Math.round(survey.ytdGrowth * paddock.area / 10000) + ' kg')
      .attr('x', 0)
      .attr('y', pos + fontSize * 1.1)
      .attr('class', 'ChartHeading')
      .style('font-size', fontSize * 0.8 + 'px')
      .attr('text-anchor', 'start');
    g
      .append('text')
      .text('(' + Math.round(survey.ytdGrowth) + ' kg/ha)')
      .attr('x', 0)
      .attr('y', pos + fontSize * 2)
      .attr('class', 'ChartHeading')
      .style('font-size', fontSize * 0.7 + 'px')
      .attr('text-anchor', 'start');
  }

  render() {
    return (
      <div
        id="InfoGraphic"
        style={{
          height: '100%',
          width: '100%'
        }}
      />
    );
  }
}

class PaddockMap extends Component {
  constructor(props) {
    super(props);
    this.createPaddockMap = this.createPaddockMap.bind(this);

    this.w = 0;
    this.h = 0;
    this.trans = Translation.getInstance().translate;
  }

  componentDidMount() {
    const chart = document.getElementById('InfoGraphic');
    this.w = chart.clientWidth;
    this.h = chart.clientHeight;
    this.createPaddockMap();
    window.addEventListener('resize', this.createPaddockMap);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.createPaddockMap);
  }

  shouldComponentUpdate(nextProps, nextState) {
    const chart = document.getElementById('InfoGraphic');
    if (this.w !== chart.clientWidth || this.h !== chart.clientHeight) {
      return true;
    }
    if (this.props.survey !== nextProps.survey) {
      return true;
    }
    if (this.props.paddock !== nextProps.paddock) {
      return true;
    }
    return false;
  }

  componentDidUpdate() {
    const chart = document.getElementById('InfoGraphic');
    this.w = chart.clientWidth;
    this.h = chart.clientHeight;
    this.createPaddockMap();
  }
  createPaddockMap() {
    const chart = document.getElementById('InfoGraphic');
    const w = chart.clientWidth;
    const h = chart.clientHeight;

    document.getElementById('InfoGraphic').innerHTML = '';

    const svg = select('#InfoGraphic')
      .append('svg')
      .attr('width', w)
      .attr('height', h);

    const self = this;
    const paddock = this.props.paddock;
    const survey = this.props.survey;

    // Find bounds of map
    const max_easting = max(paddock.coordinates, function(c) {
      return c[0];
    });
    const max_northing = max(paddock.coordinates, function(c) {
      return c[1];
    });
    const min_easting = min(paddock.coordinates, function(c) {
      return c[0];
    });
    const min_northing = min(paddock.coordinates, function(c) {
      return c[1];
    });

    const east_range = max_easting - min_easting;
    const north_range = max_northing - min_northing;

    // Define margins
    const margin = {
      top: 10,
      right: 10,
      bottom: 10,
      left: 10
    };

    // Find size of drawing region
    const width = w - margin.left - margin.right;
    const height = h - margin.top - margin.bottom;

    // Define scales
    let xScale;
    let yScale;
    const ratio = east_range * height / (north_range * width);
    if (ratio > 1) {
      // East range controls the scale
      xScale = scaleLinear()
        .domain([min_easting, max_easting])
        .range([width * 0.01, width * 0.99]);
      yScale = scaleLinear()
        .domain([min_northing, max_northing])
        .range([
          height / ratio + height * (0.99 - 1 / ratio) / 2,
          height * (1.01 - 1 / ratio) / 2
        ]);
    } else {
      // North range controls the scale
      xScale = scaleLinear()
        .domain([min_easting, max_easting])
        .range([
          width * (1.01 - ratio) / 2,
          width * ratio + width * (0.99 - ratio) / 2
        ]);
      yScale = scaleLinear()
        .domain([min_northing, max_northing])
        .range([height * 0.99, height * 0.01]);
    }

    // Define line function
    const drawPaddock = line()
      .x(function(d) {
        return xScale(d[0]);
      })
      .y(function(d) {
        return yScale(d[1]);
      })
      .curve(curveLinearClosed);

    svg
      .append('path')
      .datum(paddock)
      .attr('d', function(d) {
        return drawPaddock(d.coordinates);
      })
      .attr('class', 'Paddock')
      .attr('transform', 'translate(' + margin.left + ',' + margin.top + ')');

    svg
      .selectAll('sample')
      .data(survey.coords)
      .enter()
      .append('circle')
      .attr('transform', 'translate(' + margin.left + ',' + margin.top + ')')
      .attr('r', '5px')
      .attr('cx', function(d) {
        return xScale(d[0]);
      })
      .attr('cy', function(d) {
        return yScale(d[1]);
      })
      .attr('class', 'Point active')
      .style('opacity', survey.data.manualCover !== null ? 0.3 : 1);

    if (survey.data.manualCover !== null) {
      svg
        .append('text')
        .text(this.trans('manually_entered'))
        .attr('x', w / 2)
        .attr('y', h / 2 + h / 12 / 3)
        .attr('class', 'PaddockName')
        .style('fill', '#51534a')
        .attr('text-anchor', 'middle')
        .attr('pointer-events', 'none')
        .style('font-size', h / 12 + 'px');
    }

    svg
      .append('text')
      .attr('x', w - margin.right)
      .attr('y', h - margin.bottom)
      .attr('text-anchor', 'end')
      .attr('fill', 'var(--grass-excluded)')
      .style('cursor', 'pointer')
      .style('font-weight', 'bold')
      .text(this.trans('delete'))
      .style('pointer-events', 'all')
      .on('click', function() {
        if (window.confirm(this.trans('are_you_sure_you_want_to_delete_this_survey'))) {
          axios
            .post('https://staging.grasslandtools.ie/api/deletesurvey/', {
              sessionid: localStorage.getItem('sessionid'),
              surveyid: self.props.survey.data.survey_id
            })
            .then(function(response) {
              self.props.getDataset();
            })
            .catch(function(error) {});
        }
      });
  }

  render() {
    return (
      <div
        id="InfoGraphic"
        style={{
          height: '100%',
          width: '100%'
        }}
      />
    );
  }
}

class InfoGraphic extends Component {
  render() {
    if (!this.props.paddock) {
      return (
        <GrowthChart {...this.props} />
      );
    } else if (!this.props.survey) {
      return (
        <PaddockSummary {...this.props} />
      );
    } else {
      return (
        <PaddockMap {...this.props} />
      );
    }
  }
}

class GrowthChart extends Component {
  constructor(props) {
    super(props);
    this.createPaddockMap = this.createPaddockMap.bind(this);

    this.w = 0;
    this.h = 0;
    this.trans = Translation.getInstance().translate;
  }

  componentDidMount() {
    const chart = document.getElementById('GrowthRateChart');
    this.w = chart.clientWidth;
    this.h = chart.clientHeight;
    this.createPaddockMap();
    window.addEventListener('resize', this.createPaddockMap);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.createPaddockMap);
  }

  shouldComponentUpdate(nextProps, nextState) {
    const chart = document.getElementById('GrowthRateChart');
    if (this.w !== chart.clientWidth || this.h !== chart.clientHeight) {
      return true;
    }
    if (this.props.survey !== nextProps.survey) {
      return true;
    }
    if (this.props.paddock !== nextProps.paddock) {
      return true;
    }
    if (this.props.cover !== nextProps.cover) {
      return true;
    }
    return true;
  }

  componentDidUpdate() {
    const chart = document.getElementById('GrowthRateChart');
    this.w = chart.clientWidth;
    this.h = chart.clientHeight;
    this.createPaddockMap();
  }
  createPaddockMap() {
    const chart = document.getElementById('GrowthRateChart');
    const w = chart.clientWidth;
    const h = chart.clientHeight;

    const margin = {
      top: 35,
      right: 15,
      bottom: 30,
      left: 45
    };
    const width = w - margin.left - margin.right;
    const height = h - margin.top - margin.bottom;
    document.getElementById('GrowthRateChart').innerHTML = '';

    const svg = select('#GrowthRateChart')
      .append('svg')
      .attr('width', w)
      .attr('height', h);
    const farm = this.props.farm;
    const growths = [];
    if (farm.covers.length > 0){
      for (let i=1;i<farm.covers.length;i++) {
        growths.push({date: farm.covers[i-1].date, hectareGrowth: (farm.covers[i-1].hectareGrowth + farm.covers[i].hectareGrowth) / 2})
      }
      growths.push({date: farm.covers[farm.covers.length-1].date, hectareGrowth: farm.covers[farm.covers.length-1].hectareGrowth})
    }

    const minGrowth = 0;
    const maxGrowth = max(farm.covers, function(c) {
      return c.hectareGrowth;
    });
    const growthScale = scaleLinear()
      .domain([minGrowth, maxGrowth])
      .range([height, 0])
      .nice();

    svg
      .append('g')
      .attr('class', 'grid')
      .attr('transform', 'translate(' + margin.left + ', ' + margin.top + ')')
      .call(
        axisLeft(growthScale)
          .ticks(4)
          .tickSize(-width)
          .tickFormat('')
      );
    if (farm.survey_dates.length === 0) {
      return;
    }

    let dMin = min(farm.survey_dates, function(s) {
      return moment(s);
    });
    let dMax = max(farm.survey_dates, function(s) {
      return moment(s);
    });

    if (dMin.valueOf() === dMax.valueOf()) {
      dMin.date(dMin.date() - 1);
      dMax.date(dMax.date() + 1);
    }

    const timeScale = scaleTime()
      .domain([dMin, dMax])
      .rangeRound([0, width])
      .nice();

    const GrowthArea = area()
      .x(function(c) {
        return timeScale(moment(c.date));
      })
      .y0(height)
      .y1(function(c) {
        return growthScale(Math.max(c.hectareGrowth, 0));
      })
      .curve(curveMonotoneX);
    svg
      .append('path')
      .attr('d', GrowthArea(farm.covers.filter(c => c.hectareGrowth > 0)))
      .attr('class', 'GrowthArea')
      .attr('transform', 'translate(' + margin.left + ',' + margin.top + ')');
    if (this.props.cover) {
      svg
        .append('line')
        .attr('x1', timeScale(moment(this.props.cover.date)))
        .attr('y1', height)
        .attr('x2', timeScale(moment(this.props.cover.date)))
        .attr('y2', growthScale(this.props.cover.hectareGrowth))
        .attr('class', 'GrowthCurve')
        .style('stroke', '#f2a900')
        .style('stroke-width', '2px')
        .attr('transform', 'translate(' + margin.left + ',' + margin.top + ')');
    }
    const GrowthCurve = line()
      .x(function(c) {
        return timeScale(moment(c.date));
      })
      .y(function(c) {
        return growthScale(Math.max(c.hectareGrowth, 0));
      })
      .curve(curveMonotoneX);
    svg
      .append('path')
      .attr('d', GrowthCurve(farm.covers.filter(c => c.hectareGrowth > 0)))
      .attr('class', 'GrowthCurve')
      .attr('transform', 'translate(' + margin.left + ',' + margin.top + ')');

    let xpos = margin.left;
    let ypos = margin.top;
    svg
      .append('g')
      .attr('class', 'axis')
      .style('font-size', 12 + 'px')
      .attr('transform', 'translate(' + xpos + ',' + ypos + ')')
      .call(axisLeft(growthScale).ticks(4));

    xpos = margin.left;
    ypos = h - margin.bottom;
    svg
      .append('g')
      .attr('class', 'axis')
      .style('font-size', 12 + 'px')
      .attr('transform', 'translate(' + xpos + ',' + ypos + ')')
      .call(
        axisBottom(timeScale)
          .ticks(6)
          .tickFormat(timeFormat('%b'))
      );

      // svg
      // .append('text')
      // .attr('x', 55)
      // .attr('y', margin.top - 5)
      // .attr('class', 'LegendTitle')
      // .text(this.trans('growth_kg_ha_day'))
      // .attr('text-anchor', 'start');
    svg
      .append('text')
      .attr('x', (margin.left)+width/2)
      .attr('y', margin.top - 5)
      .attr('class', 'ChartHeading')
      .text(this.trans('growth_rate'))
      // .attr('transform', 'rotate(-90,' + (margin.left + width - 15) + ',' +
      // margin.top + ')')
      .attr('text-anchor', 'middle');
  }

  render() {
    // const trend = this.props.dataset.trend === "Flat" ? stable :
    // this.props.dataset.trend === "Falling" ? down : up
    return (
      <div
        className="ScrollPanelHalfWidth"
        style={{
          display: 'flex'
        }}
        id="GrowthChart"
      >
        <div
          id="GrowthRateChart"
          style={{
            height: '100%',
            width: '100%'
          }}
        />
      </div>
    );
  }
}

class InfoChart extends Component {
  shouldComponentUpdate(){return true;}
  render() {
    if (!this.props.pad_selected && !this.props.survey) {
      return <GrowthChart {...this.props} />;
    } else if (!this.props.survey) {
      return (
        <div
          className="ScrollPanelItem"
          style={{
            display: 'flex',
            width: "50%",
          }}
        >
          <InfoGraphic {...this.props} />
        </div>
      );
    } else {
      return (
        <div
          className="ScrollPanelItem"
          style={{
            display: 'flex',
            width: "50%",
          }}
        >
          <InfoGraphic
            dataset={this.props.dataset}
            paddock={this.props.paddock}
            survey={this.props.survey}
            getDataset={this.props.getDataset}
          />
        </div>
      );
    }
  }
}

class Dashboard extends Component {
  constructor(props) {
    super(props);
    const disp_date = max(this.props.farm.survey_dates, function(d) {
      return moment(d);
    });
    let cover = null;
    const covers = this.props.farm.covers;
    let i;
    for (i = 0; i < covers.length; i++) {
      if (moment(covers[i].date).isSame(disp_date, 'day')) {
        cover = covers[i];
        break;
      }
    }

    let regrowth = 0;
    if (i >= 3) {
      regrowth =
        0.2 * covers[i - 3].hectareGrowth +
        0.3 * covers[i - 2].hectareGrowth +
        0.5 * covers[i - 1].hectareGrowth;
    }
    if (i === 2) {
      regrowth =
        0.35 * covers[0].hectareGrowth + 0.65 * covers[1].hectareGrowth;
    }
    if (i === 1) {
      regrowth = covers[0].hectareGrowth;
    }
    if (regrowth <= 0) {
      regrowth = 1500 / 20;
    }

    this.state = {
      editingWedge: false,
      concPrice: 250,
      displayBlock: 0,
      displayGroup: 1,
      disp_dates: [disp_date],
      inc_days: 1,
      n_days: 0,
      regrowth: regrowth,
      coverType: '' + this.props.farm.wedgeSettings.coverType,
      rotLength: this.props.farm.wedgeSettings.rotLength,
      rotLengthType: this.props.farm.wedgeSettings.rotLengthType || "manual",
      demandType: this.props.farm.wedgeSettings.demandType || "simple",
      demandGroups: this.props.farm.wedgeSettings.demandGroups.length > 0 ? this.props.farm.wedgeSettings.demandGroups : [{type: "1", animalCount: this.props.farm.wedgeSettings.nAnimals, grassIntake: this.props.farm.wedgeSettings.targetIntake, mealIntake: 0, silageIntake: 0}],
      nAnimals: this.props.farm.wedgeSettings.nAnimals,
      targetIntake: this.props.farm.wedgeSettings.targetIntake,
      entryCover: this.props.farm.wedgeSettings.entryCover,
      residual: this.props.farm.wedgeSettings.residual,
      overrideDm: this.props.farm.wedgeSettings.overrideDm || false,
      dmPercent: this.props.farm.wedgeSettings.dmPercentage || 17,
      alloc_period: this.props.farm.alloc_period || 17,
      demand: this.props.farm.no_animals * (this.props.farm.alloc_period || 17),
      lu_count: 0,
      feed_cost:
        Math.round(
          (17 - this.props.farm.alloc_period) * this.props.farm.no_animals * 25
        ) / 100,
      transparency: false,
      cover: cover,
      coverCount: covers.length
    };
    this.calcCovers();
    this.trans = Translation.getInstance().translate;
  }

  reCalculateGrowth() {
    const covers = this.props.farm.covers;
    let i = covers.length-1;
    let cover = covers[i];
    // for (i = 0; i < covers.length; i++) {
    //   if (moment(covers[i].date).isSame(this.state.disp_date, 'day')) {
    //     cover = covers[i];
    //     break;
    //   }
    // }

    let regrowth = 0;
    if (i >= 3) {
      regrowth =
        0.2 * covers[i - 3].hectareGrowth +
        0.3 * covers[i - 2].hectareGrowth +
        0.5 * covers[i - 1].hectareGrowth;
    }
    if (i === 2) {
      regrowth =
        0.35 * covers[0].hectareGrowth + 0.65 * covers[1].hectareGrowth;
    }
    if (i === 1) {
      regrowth = covers[0].hectareGrowth;
    }
    if (regrowth <= 0) {
      regrowth = 1500 / 20;
    }
    // console.log(regrowth, cover, i)
    this.calcCovers();
    this.setState({
      regrowth: regrowth,
      cover: cover,
      coverCount: covers.length
    });
  }

  componentDidMount() {
    this.calcDemand(this.state.demandType);
    this.calcRotLength();
  }

  setEditingWedge() {
    this.setState({
      editingWedge: !this.state.editingWedge
    });
  }

  handleConcPriceChange(event) {
    const concPrice = parseInt(event.target.value, 10);
    this.setState({ concPrice: concPrice });
  }

  handleTargetIntakeChange(event) {
    // const targetIntake = parseFloat(event.target.value, 10);
    const targetIntake = event.target.value;
    this.setState({ targetIntake: targetIntake });
  }

  handleBlockChange(event) {
    this.setState({
      displayBlock: parseInt(event.target.value, 10)
    });
  }

  handleGroupChange(event) {
    this.setState({
      displayGroup: parseInt(event.target.value, 10)
    });
  }

  handleDateChange(date, controlKeyPressed) {
    let disp_dates = []
    let cover = null;
    const covers = this.props.farm.covers;
    let i;
    if (controlKeyPressed) {
      disp_dates = this.state.disp_dates
      // console.log(disp_dates);
      let containsDate = false
      for (let c = 0; c<disp_dates.length; c++) {
        let d = disp_dates[c];
        if (moment(d).isSame(date, 'day')) {
          containsDate = true;
          if (disp_dates.length > 1) {
            disp_dates.splice(c,1);
            // console.log("REMOVING")
          }
          break;
        }
      }
      if (!containsDate) {
        disp_dates.push(date)
      }

      disp_dates = disp_dates.sort((a,b) => moment(a.valueOf() - b.valueOf()))
      // console.log(disp_dates);

      let selectedCovers = [];
      for (let d of disp_dates) {
        for (let c of covers) {
          if (moment(c.date).isSame(d, 'day')) {
            selectedCovers.push(c);
            break;
          }
        }
      }

      const paddocks = this.props.farm.paddocks;
      let totalArea = 0;
      for (let paddock of paddocks) {
        totalArea += paddock.area / 10000
      }

      cover = {};
      cover.date = disp_dates[0].format("YYYY-MM-DD");
      cover.padCovers = [];
      cover.totalCoverArea = 0;
      cover.totalCover = 0;
      cover.hectareCover = 0;
      cover.totalGrowth = 0;
      cover.hectareGrowth = 0;
      cover.ytdTotalGrowth = 0;
      cover.ytdHectareGrowth = 0;
      for (let paddock of this.props.farm.paddocks) {
        let measured = false;
        let lastPadCover = null;
        for (let c of selectedCovers) {
          for (let padCover of c.padCovers) {
            if (padCover.paddock_id === paddock.id) {
              measured = true;
              lastPadCover = padCover;
            }
          }
        }
        if (measured) {
          cover.padCovers.push(lastPadCover);
          cover.totalCoverArea += paddock.area;
          cover.totalCover += lastPadCover.dmPaddock;
          if (lastPadCover.growthTot > 0) {
            cover.totalGrowth += lastPadCover.growthTot;
          }
        }
      }
      cover.hectareCover = Math.round((cover.totalCoverArea > 0 ? cover.totalCover/cover.totalCoverArea * 10000 : 0)*100)/100;
      cover.hectareGrowth = Math.round((cover.totalCoverArea > 0 ? cover.totalGrowth/cover.totalCoverArea * 10000 : 0)*100)/100;
      cover.ytdTotalGrowth = 400;
      cover.ytdHectareGrowth = 400;
      
      cover.totalCover = cover.padCovers.map((p)=>p.dmPaddock).reduce((a, b) => a + b, 0)
      cover.totalGrowth = cover.padCovers.map((p)=>p.growthTot).reduce((a, b) => a + b, 0)
      cover.growthArea = cover.padCovers.map((p)=>p.growthArea).reduce((a, b) => a + b, 0)
      cover.hectareGrowth = cover.growthArea > 0 ? cover.totalGrowth / cover.growthArea : 0
      cover.ytdTotalGrowth = cover.padCovers.map((p)=>p.ytdTotalGrowth).reduce((a, b) => a + b, 0)
      cover.ytdHectareGrowth = totalArea > 0 ? cover.ytdTotalGrowth / totalArea : 0
    
    }
    if (disp_dates.length === 0) {
      disp_dates.push(date)
      for (i = 0; i < covers.length; i++) {
        if (moment(covers[i].date).isSame(date, 'day')) {
          cover = covers[i];
          break;
        }
      }
    }

    for (i = 0; i < covers.length; i++) {
      if (moment(covers[i].date).isSame(moment(cover.date), 'day')) {
        break;
      }
    }
    let regrowth = 0;
    if (i >= 3) {
      regrowth =
        0.2 * covers[i - 3].hectareGrowth +
        0.3 * covers[i - 2].hectareGrowth +
        0.5 * covers[i - 1].hectareGrowth;
    }
    if (i === 2) {
      regrowth =
        0.35 * covers[0].hectareGrowth + 0.65 * covers[1].hectareGrowth;
    }
    if (i === 1) {
      regrowth = covers[0].hectareGrowth;
    }
    if (regrowth <= 0) {
      regrowth = 1500 / 20;
    }
    this.calcRotLength(regrowth, null, null);
    this.setState({ disp_dates: disp_dates, cover: cover, regrowth: regrowth });
  }

  handleDaysChange(event) {
    this.setState({
      inc_days: parseInt(event.target.value, 10)
    });
  }

  handleSliderChange(val) {
    this.setState({
      n_days: parseInt(val, 10)
    });
  }

  handleNoAnimalsChange(event) {
    const val = parseInt(event.target.value, 10) || 0;
    this.setState({ no_animals: val });
  }

  handleAllocPeriodChange(event) {
    const val = parseFloat(event.target.value) || 0;
    this.setState({ alloc_period: val });
  }

  demandKeyPress(event) {
    if (event.key === 'Enter') {
      this.calcDemand(this.state.demandType);
    }
  }

  getLUFromType(type) {
    switch (parseInt(type, 10)) {
      case 1:
        return 1;
      case 2:
        return 1;
      case 3:
        return 1;
      case 4:
        return 1;
      case 5:
        return 0.1;
      case 6:
        return 0.3;
      case 7:
        return 0.7;
      case 8:
        return 1;
      case 9:
        return 0.25;
      case 10:
        return 0.15;
      case 11:
        return 0;
      default:
        return 0;
    }
  }


  calcCovers() {
    const paddocks = this.props.farm.paddocks;
    const covers = (this.props.farm.covers)
    let totalArea = 0;
    for (let paddock of paddocks) {
      totalArea += paddock.area / 10000
      let lastCover = null;
      let lastDate = null;
      let growthHaYTD = 0;
      let growthTotYTD = 0;
      let rotationCount = 0;
      for (let cover of covers) {
        let totalCover = 0;
        cover.totalGrowth = 0;
        cover.growthArea = 0;
        let ytdTotalGrowth = 0;
        for (let survey of cover.padCovers) {
          if (survey.paddock_id === paddock.id) {
            survey.paddockName = paddock.name;
            survey.paddockArea = paddock.area/10000;
            survey.growthArea = 0;
            let growthTotDay = 0
            let growthHaDay = 0
            const area = Math.max(paddock.area, 1);
            let thisCover = (survey.manualCover !== null && !isNaN(survey.manualCover))
              ? survey.manualCover
              : this.state.coverType === '2' 
                ? Formulae.totalCover(Math.max(survey.avgHeight, 0), 0, this.state.overrideDm=="true" ? this.state.dmPercent : survey.dmPercentage)
                : Formulae.availableCover(Math.max(survey.avgHeight, 0), this.state.residual, this.state.overrideDm=="true" ? this.state.dmPercent : survey.dmPercentage);
            let thisDate = moment(cover.date);
            survey.dmHectare = thisCover;
            survey.dmPaddock = survey.dmHectare * area / 10000;
            totalCover += survey.dmPaddock;
            if (lastCover !== null && thisCover !== null) {
              let growthHa = thisCover - lastCover
              if (growthHa > -200) {
                  growthHaYTD += growthHa;
                  growthTotYTD += growthHa * area/10000;
                let growthDays = thisDate.diff(lastDate, 'days');
                growthHaDay = growthHa / growthDays;
                growthTotDay = growthHaDay * area/10000
                survey.growthArea = area/10000;
              } else {
                  rotationCount += 1;
              }
            }

            survey.growthTot = growthTotDay;
            survey.growthHa = growthHaDay;
            survey.ytdGrowth = growthHaYTD;
            survey.ytdTotalGrowth = growthTotYTD;
            survey.rotations = rotationCount;
            ytdTotalGrowth += growthTotYTD;
            
            lastCover = thisCover;
            lastDate = thisDate;
            break;
          }
        }


      }


    }
    for (let cover of covers) {
      cover.totalCover = cover.padCovers.map((p)=>p.dmPaddock).reduce((a, b) => a + b, 0)
      cover.totalGrowth = cover.padCovers.map((p)=>p.growthTot).reduce((a, b) => a + b, 0)
      cover.growthArea = cover.padCovers.map((p)=>p.growthArea).reduce((a, b) => a + b, 0)
      cover.hectareGrowth = cover.growthArea > 0 ? cover.totalGrowth / cover.growthArea : 0
      cover.ytdTotalGrowth = cover.padCovers.map((p)=>p.ytdTotalGrowth).reduce((a, b) => a + b, 0)
      cover.ytdHectareGrowth = totalArea > 0 ? cover.ytdTotalGrowth / totalArea : 0
    }

    this.setState({});
  }

  calcDemand(demandType) {
    if (demandType == "simple") {
      this.setState({
        demand: this.state.targetIntake * this.state.nAnimals,
        lu_count: this.state.nAnimals
      })
    } else if (demandType == "advanced") {
      this.setState({
        demand: this.state.demandGroups.reduce((a,b)=>a+b.animalCount*b.grassIntake,0),
        lu_count: this.state.demandGroups.reduce((a,b)=>a+b.animalCount*this.getLUFromType(b.type),0)
      })
    }
  }

  setTransparency(val) {
    this.setState({ transparency: val });
  }

  handleCoverTypeChange(event) {
    this.setState({ coverType: event.target.value },()=>this.calcCovers());;
  }

  handleRotLengthChange(event) {
    this.setState({ rotLength: event.target.value });
  }

  handleRotLengthTypeChange(event) {
    this.setState(
      {
        rotLengthType: event.target.value
      },
      this.calcRotLength
    );
  }

  handleDemandTypeChange(event) {
    this.setState(
      {
        demandType: event.target.value
      },
    );
    this.calcDemand(event.target.value)
  }

  handleDemandGroupsDetailChange(i,j,event) {
    let newGroups = this.state.demandGroups.slice();
    while (i >= newGroups.length) {
      newGroups.push({type: "1", animalCount: 0, grassIntake: 0, mealIntake: 0, silageIntake: 0});
    }
    newGroups[i][j] = event.target.value == "" ? 0 : parseFloat(event.target.value);
    if (newGroups[i]["type"] == "12") {
      newGroups.splice(i,1);
    }
    this.state.demandGroups = newGroups
    this.calcDemand(this.state.demandType)
    this.setState({
      demandGroups: newGroups
    });
  }

  handleNAnimalsChange(event) {
    this.setState({ nAnimals: event.target.value });
  }

  handleEntryCoverChange(event) {
    this.calcRotLength(null, event.target.value, null);
    this.setState({ entryCover: event.target.value });
  }

  handleResidualChange(event) {
    let residual = event.target.value;
    this.calcRotLength(null, null, residual);
    this.setState({ residual: residual },()=>this.calcCovers());;
  }

  handleDmOverrideChange(event) {
    let dmOverride = event.target.value;
    this.setState({ overrideDm: dmOverride },()=>this.calcCovers());;
  }

  handleDmPercentChange(event) {
    let dmPercent = event.target.value;
    this.setState({ dmPercent: dmPercent },()=>this.calcCovers());;
  }

  calcRotLength(regrowth, entryCover, residual) {
    if (this.state.rotLengthType === 'manual') {
      if (this.state.rotLength <= 0) {
        this.setState({rotLength: 28})
      }
      return;
    }
    if (!regrowth) {
      regrowth = this.state.regrowth;
    }
    if (!entryCover) {
      entryCover = this.state.entryCover;
    }
    if (!residual) {
      residual = this.state.residual;
    }
    const exitCover =
      this.state.coverType === '2'
        ? Formulae.availableCover(residual, 0, null)
        : 0;
    const rotLength = (entryCover - exitCover) / regrowth;
    this.setState({
      rotLength: Math.min(Math.max(Math.round(rotLength), 15), 50)
    });
  }

  componentDidUpdate(){
    if (this.props.farm.covers.length != this.state.coverCount) {
      this.reCalculateGrowth();
    }
  }

  render() {
    if (this.props.mobile) {
      return (
        <div id="ChartContainer" className="ChartContainer mobile">
        <div className={"ChartCol mobile"}>
          {this.props.fm_paddock ? (
            <PaddockHistory
              coverType={this.state.coverType}
              className="ScrollPanelItem mobile"
              farm={this.props.farm}
              paddock={this.props.ph_paddock}
              display={this.props.display}
              disp_dates={this.state.disp_dates}
              setPaddock={this.props.setPaddock}
              setDate={this.handleDateChange.bind(this)}
              survey={this.props.survey}
              coverType={this.state.coverType}
            />
          ) : (
            <Calendar
              className="ScrollPanelItem mobile"
              surveyDates={this.props.farm.survey_dates}
              setDate={this.handleDateChange.bind(this)}
              dispDates={this.state.disp_dates}
            />
          )}
          <GrassWedge
            farm={this.props.farm}
            editingWedge={this.state.editingWedge}
            coverType={this.state.coverType}
            rotLength={this.state.rotLength}
            nAnimals={this.state.nAnimals}
            targetIntake={this.state.targetIntake}
            entryCover={this.state.entryCover}
            residual={this.state.residual}
            setEditingWedge={this.setEditingWedge.bind(this)}
            className="Chart mobile"
            paddocks={this.props.farm.paddocks}
            cover={this.state.cover}
            paddock={this.props.gw_paddock}
            display={this.props.display}
            setPaddock={this.props.setPaddock}
            displayGroup={this.state.displayGroup}
            handleGroupChange={this.handleGroupChange.bind(this)}
            displayBlock={this.state.displayBlock}
            handleBlockChange={this.handleBlockChange.bind(this)}
            disp_date={this.state.disp_date}
            inc_days={this.state.inc_days}
            n_days={this.state.n_days}
            demand={this.state.demand}
            feed_cost={this.state.feed_cost}
            mobile={this.props.mobile}
          />
          <FarmMap
            coverType={this.state.coverType}
            className={'Chart mobile'}
            farm={this.props.farm}
            paddock={this.props.fm_paddock}
            display={this.props.display}
            setPaddock={this.props.setPaddock}
            residual={this.state.residual}
            cover={this.state.cover}
            coverType={this.state.coverType}
            displayGroup={this.state.displayGroup}
            displayBlock={this.state.displayBlock}
            disp_dates={this.state.disp_dates}
            inc_days={this.state.inc_days}
            handleBlockChange={this.handleBlockChange.bind(this)}
            mobile={this.props.mobile}
          />
        </div>
      </div>
      );
    }
    const blockSet = new Set();
    for (var p of this.props.farm.paddocks) {
      blockSet.add(p.block);
    }
    const activeBlocks = this.props.farm.blocks
      .map((b, i) => {
        return {
          name: b,
          number: i + 1
        };
      })
      .filter((b, i) => blockSet.has(b.number));
    activeBlocks.sort((a, b) => ('' + a.name).localeCompare(b.name));
    const blocksTabs = activeBlocks.map(b => (
      <div
        onClick={this.handleBlockChange.bind(this, {
          target: { value: b.number }
        })}
        className={
          this.state.displayBlock === b.number ? 'WedgeTab active' : 'WedgeTab'
        }
        key={b.number}
      >
        {b.name}
      </div>
    ));
    return (
      <div id="ChartContainer" className={this.props.mobile ? "ChartContainer mobile" : "ChartContainer"}>
        <div className={this.props.mobile ? "ChartCol mobile" : "ChartCol"}>
          <div className="DashboardTabList">
            <div className="TabsLabel">{this.trans('block')}</div>
            <div
              onClick={this.handleBlockChange.bind(this, {target: { value: 0 }})}
              className={this.state.displayBlock === 0 ? 'WedgeTab active' : 'WedgeTab'}
              title= {this.trans('show_the_entire_farm_together')}
            >
              {this.trans('whole_farm')}
            </div>
            <div
              onClick={this.handleBlockChange.bind(this, {target: { value: -1 }})}
              className={this.state.displayBlock === -1 ? 'WedgeTab active' : 'WedgeTab'}
              title={this.trans('show_a_mini_wedge_for_each_block')}
            >
              {this.trans('overview')}
            </div>
            {blocksTabs}
          </div>
          <div className="DashboardTabList">
            <div className="TabsLabel">{this.trans('status')}</div>
            <div
              onClick={this.handleGroupChange.bind(this, {target: { value: 1 }})}
              className={this.state.displayGroup === 1 ? 'WedgeTab active' : 'WedgeTab'}
              title={this.trans('the_paddocks_that_are_currently_used_for_grazing')}
            >
              {this.trans('grazing_platform')}
            </div>
            <div
              onClick={this.handleGroupChange.bind(this, {target: { value: 7 }})}
              className={this.state.displayGroup === 7 ? 'WedgeTab active' : 'WedgeTab'}
              title={this.trans('paddocks_that_have_been_cut_for_silage_and_added_back')}
            >
              {this.trans('silage_cut_now')}
            </div>
            <div
              onClick={this.handleGroupChange.bind(this, {target: { value: 6 }})}
              className={this.state.displayGroup === 6 ? 'WedgeTab active' : 'WedgeTab'}
              title={this.trans('paddocks_that_have_been_taken_off_the_grazing_platform_to_be_cut')}
            >
              {this.trans('silage_cut_later')}
            </div>
            <div
              onClick={this.handleGroupChange.bind(this, {target: { value: 8 }})}
              className={this.state.displayGroup === 8 ? 'WedgeTab active' : 'WedgeTab'}
              title={this.trans('paddocks_that_have_been_taken_off_the_grazing_platform_for_any_reason')}
            >
              {this.trans('closed')}
            </div>
          </div>
          <FarmMap
            coverType={this.state.coverType}
            className={'Chart'}
            farm={this.props.farm}
            paddock={this.props.fm_paddock}
            display={this.props.display}
            setPaddock={this.props.setPaddock}
            residual={this.state.residual}
            cover={this.state.cover}
            displayGroup={this.state.displayGroup}
            displayBlock={this.state.displayBlock}
            disp_dates={this.state.disp_dates}
            inc_days={this.state.inc_days}
            handleBlockChange={this.handleBlockChange.bind(this)}
            map_key={this.props.map_key}
            coverType={this.state.coverType}
          />
          <GrassWedge
            farm={this.props.farm}
            editingWedge={this.state.editingWedge}
            coverType={this.state.coverType}
            rotLength={this.state.rotLength}
            nAnimals={this.state.nAnimals}
            targetIntake={this.state.targetIntake}
            entryCover={this.state.entryCover}
            residual={this.state.residual}
            setEditingWedge={this.setEditingWedge.bind(this)}
            className="Chart"
            paddocks={this.props.farm.paddocks}
            cover={this.state.cover}
            paddock={this.props.gw_paddock}
            display={this.props.display}
            setPaddock={this.props.setPaddock}
            displayGroup={this.state.displayGroup}
            handleGroupChange={this.handleGroupChange.bind(this)}
            displayBlock={this.state.displayBlock}
            handleBlockChange={this.handleBlockChange.bind(this)}
            disp_date={this.state.disp_date}
            inc_days={this.state.inc_days}
            n_days={this.state.n_days}
            demand={this.state.demand}
            lu_count={this.state.lu_count}
            feed_cost={this.state.feed_cost}
            mobile={this.props.mobile}
          />
        </div>
        {this.props.mobile ? null : <div className={this.props.mobile ? "ChartCol mobile scroll" : "ChartCol scroll"}>
          <div className="ScrollPanelItem Row">
          {this.props.fm_paddock && this.state.cover ? (
            <PaddockHistory
              coverType={this.state.coverType}
              className="ScrollPanelHalfWidth"
              farm={this.props.farm}
              paddock={this.props.ph_paddock}
              display={this.props.display}
              disp_dates={this.state.disp_dates}
              setPaddock={this.props.setPaddock}
              setDate={this.handleDateChange.bind(this)}
              survey={this.props.survey}
            />
          ) : (
            <InfoChart
            farm={this.props.farm}
            cover={this.state.cover}
            displayGroup={this.state.displayGroup}
            paddock={this.props.ph_paddock}
            pad_selected={
              this.props.gw_paddock || this.props.fm_paddock ? true : false
            }
            survey={this.props.survey}
            getDataset={this.props.getDataset}
          />
          )}
          {this.props.fm_paddock && this.state.cover ? (
            <InfoChart
            farm={this.props.farm}
            cover={this.state.cover}
            displayGroup={this.state.displayGroup}
            paddock={this.props.ph_paddock}
            pad_selected={
              this.props.gw_paddock || this.props.fm_paddock ? true : false
            }
            survey={this.props.survey}
            getDataset={this.props.getDataset}
          />
          ) : (
            <Calendar
              className="ScrollPanelHalfWidth"
              surveyDates={this.props.farm.survey_dates}
              setDate={this.handleDateChange.bind(this)}
              dispDates={this.state.disp_dates}
            />
          )}
        </div>
          {this.state.cover ? <MetricsPanel
            farm={this.props.farm}
            editingWedge={this.state.editingWedge}
            rotLengthType={this.state.rotLengthType}
            handleRotLengthTypeChange={this.handleRotLengthTypeChange.bind(this)}
            demandType={this.state.demandType}
            handleDemandTypeChange={this.handleDemandTypeChange.bind(this)}
            updateDatasetStatus={this.props.updateDatasetStatus}
            setEditingWedge={this.setEditingWedge.bind(this)}
            coverType={this.state.coverType}
            handleCoverTypeChange={this.handleCoverTypeChange.bind(this)}
            rotLength={this.state.rotLength}
            handleRotLengthChange={this.handleRotLengthChange.bind(this)}
            nAnimals={this.state.nAnimals}
            handleNAnimalsChange={this.handleNAnimalsChange.bind(this)}
            targetIntake={this.state.targetIntake}
            handleTargetIntakeChange={this.handleTargetIntakeChange.bind(this)}
            entryCover={this.state.entryCover}
            handleEntryCoverChange={this.handleEntryCoverChange.bind(this)}
            residual={this.state.residual}
            handleResidualChange={this.handleResidualChange.bind(this)}
            className="Chart"
            paddocks={this.props.farm.paddocks}
            cover={this.state.cover}
            paddock={this.props.gw_paddock}
            display={this.props.display}
            setPaddock={this.props.setPaddock}
            displayGroup={this.state.displayGroup}
            handleGroupChange={this.handleGroupChange.bind(this)}
            displayBlock={this.state.displayBlock}
            handleBlockChange={this.handleBlockChange.bind(this)}
            disp_date={this.state.disp_date}
            inc_days={this.state.inc_days}
            n_days={this.state.n_days}
            demand={this.state.demand}
            lu_count={this.state.lu_count}
            survey={this.props.survey}
          /> : null}
          {this.state.cover ? <CoverSettings
            farm={this.props.farm}
            editingWedge={this.state.editingWedge}
            rotLengthType={this.state.rotLengthType}
            handleRotLengthTypeChange={this.handleRotLengthTypeChange.bind(this)}
            demandType={this.state.demandType}
            handleDemandTypeChange={this.handleDemandTypeChange.bind(this)}
            updateDatasetStatus={this.props.updateDatasetStatus}
            setEditingWedge={this.setEditingWedge.bind(this)}
            coverType={this.state.coverType}
            handleCoverTypeChange={this.handleCoverTypeChange.bind(this)}
            rotLength={this.state.rotLength}
            handleRotLengthChange={this.handleRotLengthChange.bind(this)}
            nAnimals={this.state.nAnimals}
            handleNAnimalsChange={this.handleNAnimalsChange.bind(this)}
            targetIntake={this.state.targetIntake}
            handleTargetIntakeChange={this.handleTargetIntakeChange.bind(this)}
            entryCover={this.state.entryCover}
            handleEntryCoverChange={this.handleEntryCoverChange.bind(this)}
            residual={this.state.residual}
            handleResidualChange={this.handleResidualChange.bind(this)}
            dmPercent={this.state.dmPercent}
            handleDmPercentChange={this.handleDmPercentChange.bind(this)}
            overrideDm={this.state.overrideDm}
            handleDmOverrideChange={this.handleDmOverrideChange.bind(this)}
            handleDemandGroupsDetailChange={this.handleDemandGroupsDetailChange.bind(this)}
            className="Chart"
            paddocks={this.props.farm.paddocks}
            cover={this.state.cover}
            paddock={this.props.gw_paddock}
            display={this.props.display}
            setPaddock={this.props.setPaddock}
            displayGroup={this.state.displayGroup}
            handleGroupChange={this.handleGroupChange.bind(this)}
            displayBlock={this.state.displayBlock}
            handleBlockChange={this.handleBlockChange.bind(this)}
            disp_date={this.state.disp_date}
            inc_days={this.state.inc_days}
            n_days={this.state.n_days}
            demand={this.state.demand}
            demandGroups={this.state.demandGroups}
            feed_cost={this.state.feed_cost}
          /> : null}
          <PaddockCovers
            getDataset={this.props.getDataset}
            farm={this.props.farm}
          />
          <OutputsPanel
            farm={this.props.farm}
            cover={this.state.cover}
            disp_dates={this.state.disp_dates}
          />
          <PaddockStatus
            farm={this.props.farm}
            editingWedge={this.state.editingWedge}
            rotLengthType={this.state.rotLengthType}
            handleRotLengthTypeChange={this.handleRotLengthTypeChange.bind(this)}
            demandType={this.state.demandType}
            handleDemandTypeChange={this.handleDemandTypeChange.bind(this)}
            updateDatasetStatus={this.props.updateDatasetStatus}
            setEditingWedge={this.setEditingWedge.bind(this)}
            coverType={this.state.coverType}
            handleCoverTypeChange={this.handleCoverTypeChange.bind(this)}
            rotLength={this.state.rotLength}
            handleRotLengthChange={this.handleRotLengthChange.bind(this)}
            nAnimals={this.state.nAnimals}
            handleNAnimalsChange={this.handleNAnimalsChange.bind(this)}
            targetIntake={this.state.targetIntake}
            handleTargetIntakeChange={this.handleTargetIntakeChange.bind(this)}
            entryCover={this.state.entryCover}
            handleEntryCoverChange={this.handleEntryCoverChange.bind(this)}
            residual={this.state.residual}
            handleResidualChange={this.handleResidualChange.bind(this)}
            className="Chart"
            paddocks={this.props.farm.paddocks}
            cover={this.state.cover}
            paddock={this.props.gw_paddock}
            display={this.props.display}
            setPaddock={this.props.setPaddock}
            displayGroup={this.state.displayGroup}
            handleGroupChange={this.handleGroupChange.bind(this)}
            displayBlock={this.state.displayBlock}
            handleBlockChange={this.handleBlockChange.bind(this)}
            disp_date={this.state.disp_date}
            inc_days={this.state.inc_days}
            n_days={this.state.n_days}
            demand={this.state.demand}
            feed_cost={this.state.feed_cost}
            getDataset={this.props.getDataset}
          />
          <PaddockBlocks
            farm={this.props.farm}
            updateDatasetBlocks={this.props.updateDatasetBlocks}
            getDataset={this.props.getDataset}
          />
          <PaddockGrazing
            paddock={this.props.fm_paddock}
          />
          <PaddockSilage
            paddock={this.props.fm_paddock}
          />
          <PaddockReseed
            paddock={this.props.fm_paddock}
            cultivars={this.props.cultivars}
          />
          <PaddockFertiliser
            paddock={this.props.fm_paddock}
            fertilisers={this.props.fertilisers}
          />
          <div style={{flexGrow: 1}}></div>
          <div className="CopyrightContainer">
            <span>
              {this.trans('copyright') + ' '} © 2017 - {moment().format('YYYY')} True North Technologies Ltd.
            </span>
          </div>
        </div>}
      </div>
    );
  }
}

export default Dashboard;
