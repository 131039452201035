import React, { Component } from "react";
import { max, min, mean, quantile } from "d3-array";
import { axisLeft, axisBottom } from "d3-axis";
import { hsl } from "d3-color";
import { scaleLinear, scaleTime } from "d3-scale";
import { arc, area, line, curveMonotoneX } from "d3-shape";
import { select } from "d3-selection";
import Translation from "./Translation";

import moment from "moment-with-locales-es6";

moment.locale(Translation.getInstance().langCode());

class RadialRangeChart extends Component {
  constructor(props) {
    super(props);
    this.createChart = this.createChart.bind(this);
    this.trans = Translation.getInstance().translate;
  }

  componentDidMount() {
    this.createChart();
    window.addEventListener("resize", this.createChart);
  }

  createChart() {
    select(".tooltip").remove();

    const chart = document.getElementById(this.props.id);
    if (!chart) {
      return;
    }

    document.getElementById(this.props.id).innerHTML = "";

    // Get height and width
    const h = chart.clientHeight;
    const w = chart.clientWidth;

    // Define margins
    const margin = {
      top: 35,
      right: 55,
      bottom: 10,
      left: 55
    };

    // Find size of drawing region
    const width = w - margin.left - margin.right;
    const height = h - margin.top - margin.bottom;

    const measurements = this.props.measurements;
    const variable = this.props.variable;

    const values = measurements
      .filter(m => m[variable] !== null)
      .map(m => m[variable]);
    values.sort();
    const average = mean(values);
    const minimum = min(values);
    const maximum = max(values);
    const delta = Math.min(maximum - average, average - minimum) / 2;
    const lowCount = values.filter(v => v < average - delta).length;
    const highCount = values.filter(v => v > average + delta).length;
    const normalCount = values.length - lowCount - highCount;
    // Create SVG
    const svg = select("#" + this.props.id)
      .append("svg")
      .attr("width", w)
      .attr("height", h);

    // Append background rect
    svg
      .append("rect")
      .attr("x", 0)
      .attr("y", 0)
      .attr("width", w)
      .attr("height", h)
      .attr("fill", "#ffffff00");

    if (values.length === 0) {
      svg
        .append("text")
        .attr("x", margin.left + width / 2)
        .attr("y", margin.top + height / 2)
        .attr("class", "LegendTitle")
        .style("font-weight", "bold")
        .style("font-size", "1.3em")
        .text(
          this.trans("insufficent data to draw ** chart").replace(
            "**",
            this.trans(this.props.label)
          )
        )
        .attr("text-anchor", "middle");
      return;
    }
    // svg
    //   .append("rect")
    //   .attr("x", margin.left)
    //   .attr("y", margin.top)
    //   .attr("width", width)
    //   .attr("height", height)
    //   .attr("fill", "rgb(229, 199, 138)");

    const multipier = 1.3;
    const scalingFactor = 1.479;
    const outerRadius = Math.min(height * 1.18, width / 2);
    const innerRadius = outerRadius * 0.58;
    var arcGenerator = arc()
      .innerRadius(innerRadius)
      .outerRadius(outerRadius)
      .startAngle(d => -1.3 + 2.6 * d.start)
      .endAngle(d => -1.3 + 2.6 * d.end)
      .padAngle(0.01);
    var g = svg
      .append("g")
      .attr(
        "transform",
        "translate(" +
          (margin.left + width / 2) +
          "," +
          (margin.top + height / 2 + innerRadius) +
          ")"
      );

    const poor = { start: 0, end: lowCount / values.length };
    g.append("path")
      .attr("class", "arc")
      .attr("d", arcGenerator(poor))
      .attr("fill", "rgb(229, 138, 138)");

    const middle = {
      start: lowCount / values.length,
      end: 1 - highCount / values.length
    };
    g.append("path")
      .attr("class", "arc")
      .attr("d", arcGenerator(middle))
      .attr("fill", "rgb(138, 199, 229)");

    const good = { start: 1 - highCount / values.length, end: 1 };
    g.append("path")
      .attr("class", "arc")
      .attr("d", arcGenerator(good))
      .attr("fill", "rgb(168, 229, 138)");

    let labelScale = minimum > 100 ? 1 : minimum > 1 ? 10 : 100;
    let averageLocation = values.indexOf(average);
    if (averageLocation === -1) {
      averageLocation = values.filter(v => v < average).length;
    } else {
      averageLocation += 1 / 2;
    }

    averageLocation = -1.3 + (averageLocation / values.length) * 2.6;

    g.append("line")
      .attr("x1", Math.sin(averageLocation) * outerRadius)
      .attr("y1", -Math.cos(averageLocation) * outerRadius)
      .attr("x2", Math.sin(averageLocation) * innerRadius)
      .attr("y2", -Math.cos(averageLocation) * innerRadius)
      .attr("stroke", "rgb(201, 44, 44)")
      .attr("stroke-width", "3px");
    g.append("text")
      .attr("x", Math.sin(averageLocation) * outerRadius * 1.03)
      .attr("y", -Math.cos(averageLocation) * outerRadius * 1.03)
      .attr("class", "LegendTitle")
      .style("font-weight", "bold")
      .style("font-size", "1.1em")
      .text(
        Math.round(average * labelScale) / labelScale +
          " " +
          this.trans(this.props.unit)
      )
      .attr("text-anchor", "middle");
    if (
      this.props.selected_animal &&
      this.props.selected_animal[variable] !== null
    ) {
      let selectedLocation = values.indexOf(
        this.props.selected_animal[variable]
      );
      if (selectedLocation === -1) {
        selectedLocation = values.filter(v => v < average).length;
      } else {
        selectedLocation += 1 / 2;
      }

      selectedLocation = -1.3 + (selectedLocation / values.length) * 2.6;
      g.append("line")
        .attr("x1", Math.sin(selectedLocation) * outerRadius)
        .attr("y1", -Math.cos(selectedLocation) * outerRadius)
        .attr("x2", Math.sin(selectedLocation) * innerRadius)
        .attr("y2", -Math.cos(selectedLocation) * innerRadius)
        .attr("stroke", "#f2ba00")
        .attr("stroke-width", "3px");
      g.append("text")
        .attr("x", Math.sin(selectedLocation) * innerRadius * 0.9)
        .attr("y", 5 - Math.cos(selectedLocation) * innerRadius * 0.9)
        .attr("class", "LegendTitle")
        .style("font-weight", "bold")
        .style("font-size", "1.2em")
        .text(
          Math.round(this.props.selected_animal[variable] * labelScale) /
            labelScale +
            " " +
            this.trans(this.props.unit)
        )
        .attr("text-anchor", "middle");
    }

    if (lowCount > 0) {
      const poorArcCentroid = arcGenerator.centroid(poor);
      g.append("text")
        .attr("x", poorArcCentroid[0])
        .attr("y", poorArcCentroid[1] + 5)
        .attr("class", "LegendTitle")
        .style("font-weight", "bold")
        .style("font-size", "1.1em")
        .text(lowCount)
        .attr("text-anchor", "middle");
    }
    if (highCount > 0) {
      const goodArcCentroid = arcGenerator.centroid(good);
      g.append("text")
        .attr("x", goodArcCentroid[0])
        .attr("y", goodArcCentroid[1] + 5)
        .attr("class", "LegendTitle")
        .style("font-weight", "bold")
        .style("font-size", "1.1em")
        .text(highCount)
        .attr("text-anchor", "middle");
    }
    if (lowCount + highCount < values.length) {
      const middleArcCentroid = arcGenerator.centroid(middle);
      g.append("text")
        .attr("x", middleArcCentroid[0])
        .attr("y", middleArcCentroid[1] + 5)
        .attr("class", "LegendTitle")
        .style("font-weight", "bold")
        .style("font-size", "1.1em")
        .text(values.length - (lowCount + highCount))
        .attr("text-anchor", "middle");
    }

    // var arcGenerator2 = arc()
    //   .innerRadius(innerRadius)
    //   .outerRadius(innerRadius)
    //   .startAngle(d => -1.3)
    //   .endAngle(d => 1.3);
    // g.append("path")
    //   .attr("class", "arc")
    //   .attr("d", arcGenerator2)
    //   .attr("stroke", "#51534a");
    // g.append("line")
    // .attr("x1", Math.sin(-1.3)*outerRadius*1.01)
    // .attr("y1", -Math.cos(-1.3)*outerRadius*1.01)
    // .attr("x2", Math.sin(-1.3)*outerRadius* 0.6)
    // .attr("y2", -Math.cos(-1.3)*outerRadius* 0.6)
    // .attr("stroke", "#51534a");
    // g.append("line")
    // .attr("x1", Math.sin(1.3)*outerRadius*1.01)
    // .attr("y1", -Math.cos(1.3)*outerRadius*1.01)
    // .attr("x2", Math.sin(1.3)*outerRadius* 0.6)
    // .attr("y2", -Math.cos(1.3)*outerRadius* 0.6)
    // .attr("stroke", "#51534a");
    g.append("text")
      .attr("x", Math.sin(-1.3) * outerRadius * 1.01)
      .attr("y", 5 - Math.cos(-1.3) * outerRadius * 1.01)
      .attr("class", "LegendTitle")
      .style("font-weight", "bold")
      .style("font-size", "1.1em")
      .text(
        Math.floor(minimum * labelScale) / labelScale +
          " " +
          this.trans(this.props.unit)
      )
      .attr("text-anchor", "end");
    g.append("text")
      .attr("x", Math.sin(1.3) * outerRadius * 1.01)
      .attr("y", 5 - Math.cos(1.3) * outerRadius * 1.01)
      .attr("class", "LegendTitle")
      .style("font-weight", "bold")
      .style("font-size", "1.1em")
      .text(
        Math.ceil(maximum * labelScale) / labelScale +
          " " +
          this.trans(this.props.unit)
      )
      .attr("text-anchor", "start");

    g.append("text")
      .attr("x", 0)
      .attr("y", -innerRadius / 3)
      .attr("class", "LegendTitle")
      .style("font-weight", "bold")
      .style("font-size", "1.5em")
      .text(this.trans(this.props.label))
      .attr("text-anchor", "middle");
    // g.append("text")
    //   .attr("x", 0)
    //   .attr("y", -innerRadius / 3)
    //   .attr("class", "LegendTitle")
    //   // .style("font-weight", "bold")
    //   // .style("font-size", "1.3em")
    //   .text(this.trans(this.props.unit))
    //   .attr("text-anchor", "middle");
  }

  render() {
    this.createChart();
    return (
      <div
        id={this.props.id}
        style={{
          position: "relative",
          height: this.props.height,
          width: this.props.width
        }}
      />
    );
  }
}

export default RadialRangeChart;
