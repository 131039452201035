import React, { Component } from 'react';
import { max, min, mean, quantile } from 'd3-array';
import { axisLeft, axisBottom } from 'd3-axis';
import { hsl, rgb } from 'd3-color';
import { scaleLinear, scaleTime } from 'd3-scale';
import { area, line, curveMonotoneX } from 'd3-shape';
import { select } from 'd3-selection';
import Translation from './Translation';

import moment from 'moment-with-locales-es6';

moment.locale(Translation.getInstance().langCode());

class HerdProgression extends Component {
  constructor(props) {
    super(props);
    this.createHerdProgression = this.createHerdProgression.bind(this);
    this.trans = Translation.getInstance().translate;
  }

  componentDidMount() {
    this.createHerdProgression();
    window.addEventListener('resize', this.createHerdProgression);
  }

  createHerdProgression() {
    select('.tooltip').remove();

    const chart = document.getElementById(this.props.id);
    if (!chart) {
      return;
    }

    document.getElementById(this.props.id).innerHTML = '';

    // Get height and width
    const h = chart.clientHeight;
    const w = chart.clientWidth;

    // Define margins
    const margin = {
      top: 25,
      right: 10,
      bottom: 25,
      left: 45
    };

    // Find size of drawing region
    const width = w - margin.left - margin.right;
    const height = h - margin.top - margin.bottom;

    // Create SVG
    const svg = select('#' + this.props.id)
      .append('svg')
      .attr('width', w)
      .attr('height', h);

    // Append background rect
    svg
      .append('rect')
      .attr('x', 0)
      .attr('y', 0)
      .attr('width', w)
      .attr('height', h)
      .attr('fill', '#ffffff00');
    // .on('click', selectAnimal.bind(null));
    const measurements = this.props.measurements;
    const selectedAnimals = this.props.selectedAnimals;
    const animal = this.props.selected_animal;
    const dataset = [];
    for (let m of measurements) {
      const am = [];
      for (let ami of m.measurements) {
        for (let a of selectedAnimals) {
          if (a.tag_no === ami.tag_no) {
            am.push(ami);
            break;
          }
        }
      }
      let animalValue = null;
      if (am.length > 0) {
        for (let m of am) {
          if (animal && m.tag_no === animal.tag_no) {
            animalValue = parseFloat(m[this.props.variable]);
            break;
          }
        }
        am.sort(
          (a, b) =>
            parseFloat(a[this.props.variable]) -
            parseFloat(b[this.props.variable])
        );
        dataset.push({
          date: m.timestamp,
          min: min(am, d => parseFloat(d[this.props.variable])),
          max: max(am, d => parseFloat(d[this.props.variable])),
          mean: mean(am, d => parseFloat(d[this.props.variable])),
          ninety: quantile(am, 0.8, d => parseFloat(d[this.props.variable])),
          ten: quantile(am, 0.2, d => parseFloat(d[this.props.variable])),
          animal: animalValue
        });
      }
    }
    dataset.sort(function(a, b) {
      return moment(b.date).diff(moment(a.date));
    });
    let dMin;
    let dMax;
    if (dataset.length > 0) {
      dMin = min(dataset, function(d) {
        return moment(d.date);
      });
      dMax = max(dataset, function(d) {
        return moment(d.date);
      });
    } else {
      dMin = moment();
      dMax = moment();
    }

    if (dMin.valueOf() === dMax.valueOf()) {
      dMin.date(dMin.date() - 1);
      dMax.date(dMax.date() + 1);
    }

    const xScale = scaleTime()
      .domain([dMin, dMax])
      .rangeRound([0, width]);
    // .nice(); const xScale = scaleTime()   .range([0, width])   .domain(
    // dataset.map(d => {       return d.date;     })   );

    let minY = min(dataset, d => {
      return d.min;
    });
    let maxY = max(dataset, d => {
      return d.max;
    });

    const rangeY = maxY - minY;

    minY -= rangeY / 8;
    minY = Math.max(minY, 0);
    maxY += rangeY / 8;

    const yScale = scaleLinear()
      .range([height, 0])
      .domain([minY, maxY]);

    svg
      .append('g')
      .attr('class', 'grid')
      .attr('transform', 'translate(' + margin.left + ', ' + margin.top + ')')
      .call(
        axisLeft(yScale)
          .ticks(6)
          .tickSize(-width)
          .tickFormat('')
      );

    const graph = svg
      .append('g')
      .attr('transform', 'translate(' + margin.left + ', ' + margin.top + ')');

    // graph   .append('line')   .attr('x1', xScale(disp_date))   .attr('y1', 0)
    // .attr('x2', xScale(disp_date))   .attr('y2', height)   .attr('stroke',
    // '#51534a')   .attr('stroke-width', '3px')   .style('pointer-events', 'none');

    const meanLine = line()
      .x(function(d) {
        return xScale(moment(d.date));
      })
      .y(function(d) {
        return yScale(d.mean);
      })
      .curve(curveMonotoneX);

    const outerRange = area()
      .x(d => xScale(moment(d.date)))
      .y0(d => yScale(d.min))
      .y1(d => yScale(d.max))
      .curve(curveMonotoneX);

    const innerRange = area()
      .x(d => xScale(moment(d.date)))
      .y0(d => yScale(d.ninety))
      .y1(d => yScale(d.ten))
      .curve(curveMonotoneX);

    graph
      .append('path')
      .attr('d', outerRange(dataset))
      // .attr('class', 'HistoryLine')
      // .style('fill', hsl(184, 0.67, 0.35, 0.3))
      .style('fill', rgb(138, 199, 229, 0.5));
    graph
      .append('path')
      .attr('d', innerRange(dataset))
      // .attr('class', 'HistoryLine') .style('fill', hsl(103, .55, .46, 1));
      // .style('fill', hsl(184, 0.67, 0.35, 0.3));
      .style('fill', rgb(138, 199, 229, 0.5));
    graph
      .append('path')
      .attr('d', meanLine(dataset))
      .attr('stroke', rgb(201, 44, 44))
      .attr('stroke-width', '3px')
      .style('fill', 'none');

    if (animal) {
      const animalLine = line()
        .x(function(d) {
          return xScale(moment(d.date));
        })
        .y(function(d) {
          return yScale(d.animal);
        })
        .curve(curveMonotoneX);

      graph
        .append('path')
        .attr('d', animalLine(dataset.filter(d => d.animal)))
        .attr('stroke', "#f2ba00")
        .attr('stroke-width', '3px')
        .style('fill', 'none');

      graph
        .selectAll('circle')
        .data(dataset.filter(d => d.animal))
        .enter()
        .append('circle')
        .attr('cx', d => {
          return xScale(moment(d.date));
        })
        .attr('cy', d => {
          return yScale(d.animal);
        })
        .attr('r', 5)
        .attr('fill', "#f2ba00");

      // const textBoxPadding = 10;
      // const avgBox = svg.append('g').style('fill', 'red');
      // const avgBoxBackdrop = avgBox.append('g');
      // avgBox
      //   .append('text')
      //   .text(this.trans('average_daily_gain_svg'))
      //   .attr('x', textBoxPadding / 2 + 80)
      //   .attr('y', 15)
      //   .attr('class', 'LegendTitle')
      //   .style('font-size', '16px')
      //   .style('font-weight', 'bold')
      //   .attr('text-anchor', 'middle');

      // avgBox
      //   .append('text')
      //   .text(animal.tag_no)
      //   .attr('x', textBoxPadding / 2 + 80)
      //   .attr('y', 35)
      //   .attr('class', 'LegendTitle')
      //   .style('font-weight', 'bold')
      //   .attr('text-anchor', 'middle');

      // avgBox
      //   .append('text')
      //   .text(this.trans('lifetime_svg') + ':')
      //   .attr('x', textBoxPadding / 2)
      //   .attr('y', 55)
      //   .attr('class', 'LegendTitle')
      //   .attr('text-anchor', 'start');

      // avgBox
      //   .append('text')
      //   .text(
      //     '' +
      //     Math.round(animal.adg_total * 100) / 100 +
      //     ' ' +
      //     this.trans('kg_per_day_svg')
      //   )
      //   .attr('x', textBoxPadding / 2 + 160)
      //   .attr('y', 55)
      //   .attr('class', 'LegendTitle')
      //   .attr('text-anchor', 'end');

      // avgBox
      //   .append('text')
      //   .text(this.trans('this_year_svg') + ':')
      //   .attr('x', textBoxPadding / 2)
      //   .attr('y', 75)
      //   .attr('class', 'LegendTitle')
      //   .attr('text-anchor', 'start');

      // avgBox
      //   .append('text')
      //   .text(
      //     '' +
      //     Math.round(animal.adg_year * 100) / 100 +
      //     ' ' +
      //     this.trans('kg_per_day_svg')
      //   )
      //   .attr('x', textBoxPadding / 2 + 160)
      //   .attr('y', 75)
      //   .attr('class', 'LegendTitle')
      //   .attr('text-anchor', 'end');

      // const bbox = avgBox.node().getBBox();

      // avgBoxBackdrop
      //   .append('rect')
      //   .attr('x', bbox.x - textBoxPadding / 2)
      //   .attr('y', 0)
      //   .attr('width', bbox.width + textBoxPadding)
      //   .attr('height', 82)
      //   .attr('class', 'TextBackdrop');

      // avgBox.attr(
      //   'transform',
      //   'translate(' +
      //   (margin.left + textBoxPadding / 2) +
      //   ',' +
      //   (margin.top + textBoxPadding / 2) +
      //   ')'
      // );
    }

    svg
      .append('g')
      .attr('class', 'axis')
      .style('font-size', 12 + 'px')
      .attr('transform', 'translate(' + margin.left + ',' + margin.top + ')')
      .call(axisLeft(yScale).ticks(6));

    svg
      .append('g')
      .attr('class', 'axis')
      .style('font-size', 12 + 'px')
      .attr(
        'transform',
        'translate(' + margin.left + ',' + (margin.top + height) + ')'
      )
      .call(axisBottom(xScale).ticks(5));

    svg
      .append('text')
      .attr('x', margin.left)
      .attr('y', margin.top - 10)
      .attr('class', 'LegendTitle')
      .text(this.trans(this.props.label || this.props.variable))
      .attr('text-anchor', 'start');

    // Add heading
    // svg
    //   .append('text')
    //   .text("Trump Farms, Doonbeg, Co. Clare. (C2310911)")
    //   // .text(this.trans('progression'))
    //   .attr('x', w / 2)
    //   .attr('y', 40)
    //   .attr('class', 'ChartHeading')
    //   .attr('text-anchor', 'middle');
  }

  render() {
    this.createHerdProgression();
    return (
      <div
        id={this.props.id}
        style={{
          position: 'relative',
          height: this.props.height,
          width: this.props.width
        }}
      />
    );
  }
}

export default HerdProgression;
