import React, { Component } from "react";
import moment from "moment-with-locales-es6";
import axios from "axios";

import Flags from "./Flags";
import Icon from "./img/GrasslandToolsLogoNewGW.png";
import Drawing from "./img/Landing_Page_Line_Dwg_2.png";
// import Dashboard from "./img/Dashboard.png";
import Grass from "./img/Grass.png";
import Environment from "./img/Growth.png";
import Weighing from "./img/Weighing.png";
import Field from "./img/Field.png";
import UserController from "./UserController";

import Translation from "./Translation";

import "./LandingPage.css";
import SignupPage from "./SignupPage";

class LandingPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      creating: false,
      domain: null,
      enabled: ["Grass", "Weighing", "Environment"],
      selected: null,
      action: "Login",
      username: "",
      password: "",
      fullname: "",
      phoneNumber: "",
      sent: false,
      haveLead: localStorage.getItem("lead_uuid") ? true : false
    };
    this.handleUsernameChange = this.handleUsernameChange.bind(this);
    this.handlePasswordChange = this.handlePasswordChange.bind(this);
    this.handleFullNameChange = this.handleFullNameChange.bind(this);
    this.handlePhoneNumberChange = this.handlePhoneNumberChange.bind(this);
    this.handleInput = this.handleInput.bind(this);
    this.createLead = this.createLead.bind(this);
    this.handleLoginKeyPress = this.handleLoginKeyPress.bind(this);
    this.handleSignupKeyPress = this.handleSignupKeyPress.bind(this);
    this.setAppState = this.props.setAppState.bind(this);
    this.trans = Translation.getInstance().translate;
  }

  setAction(x) {
    this.setState({ action: x, error: "" });
  }

  handleUsernameChange(event) {
    this.setState({ username: event.target.value });
  }
  handlePasswordChange(event) {
    this.setState({ password: event.target.value });
  }

  handleFullNameChange(event) {
    this.setState({ fullname: event.target.value });
  }
  handlePhoneNumberChange(event) {
    this.setState({ phoneNumber: event.target.value });
  }

  handleInput(event) {
    const self = this;
    if (!this.state.username) {
      self.setState({
        error: this.trans("input_username")
      });
      return;
    }
    if (!this.state.password) {
      self.setState({
        error: this.trans("input_password")
      });
      return;
    }
    self.setState({ error: "", sent: true });
    const setAppState = this.setAppState;
    const username = this.state.username;
    const password = this.state.password;
    axios
      .post("https://staging.grasslandtools.ie/api/login/", {
        username: username,
        password: password
      })
      .then(function(response) {
        if (response.data.sessionid) {
          UserController.setSessionkey(response.data.sessionid);
          UserController.setEmail(response.data.email);
          UserController.setWhitelist(response.data.whitelist);
          localStorage.setItem("sessionid", response.data.sessionid);
          self.props.getDataset();
          setAppState({ userLoggedIn: true });
        }
        if (response.data.error) {
          self.setState({ error: response.data.error, sent: false });
        }
      })
      .catch(function(error) {
        alert(error);
        self.setState({ sent: false });
      });
    event.preventDefault();
  }

  createLead(event) {
    const self = this;
    if (this.state.fullname.length < 5) {
      self.setState({
        error: this.trans("please_enter_your_name_above")
      });
      return;
    }
    if (this.state.phoneNumber.length < 8) {
      self.setState({
        error: this.trans("please_enter_your_phone_number_above")
      });
      return;
    }
    self.setState({ error: "", sent: true });
    const setAppState = this.setAppState;
    const full_name = this.state.fullname;
    const phone_number = this.state.phoneNumber;
    axios
      .post("https://staging.grasslandtools.ie/api/v2/createlead/", {
        full_name: full_name,
        phone_number: phone_number
      })
      .then(function(response) {
        if (response.data.uuid) {
          localStorage.setItem("lead_uuid", response.data.uuid);
          self.setState({ haveLead: true, sent: false });
        }
        if (response.data.error) {
          self.setState({ error: response.data.error, sent: false });
        }
      })
      .catch(function(error) {
        self.setState({ error: self.trans("failed_to_reach_server"), sent: false });
      });
    event.preventDefault();
  }

  handleLoginKeyPress = event => {
    if (event.key === "Enter") {
      this.handleInput(event);
    }
  };

  handleSignupKeyPress = event => {
    if (event.key === "Enter") {
      this.createLead(event);
    }
  };

  render() {
    const self = this;
    if (this.state.haveLead) {
      return (
        <SignupPage
          incrementKey={this.props.incrementKey.bind(this)}
          setAppState={this.props.setAppState.bind(this)}
          getDataset={this.props.getDataset.bind(this)}
          display={this.props.display}
          setDisplay={this.props.setDisplay.bind(this)}
          logout={this.props.logout.bind(this)}
          userLoggedIn={this.props.userLoggedIn}
        />
      );
    }
    return (
      <div className="LandingPage">
        <div className="LandingHeader">
          <div className="IconDiv" onClick={this.props.goHome}>
            <img className="Icon" src={Icon} alt="Icon" />
            <div className="LandingPageFlagsContainer">
              <Flags incrementKey={this.props.incrementKey} />
            </div>
          </div>
          <div className="LandingPageLoginContainer">
            <div className="LandingPageButtonContainer">
              <div
                className={
                  "Button LandingPageLoginButton" +
                  (this.state.action == "Login"
                    ? " LandingPageActiveButton"
                    : "")
                }
                onClick={this.setAction.bind(this, "Login")}
              >
                {this.trans("login")}
              </div>
              <div
                className={
                  "Button LandingPageLoginButton" +
                  (this.state.action == "Signup"
                    ? " LandingPageActiveButton"
                    : "")
                }
                onClick={this.setAction.bind(this, "Signup")}
              >
                {this.trans("create_account")}
              </div>
            </div>
            {this.state.sent ? (
              <div className="LandingPageLoginBody">
                <p>{this.trans("loading___")}</p>
              </div>
            ) : this.state.action == "Login" ? (
              <div className="LandingPageLoginBody">
                <label>{this.trans("username")}</label>
                <input
                  className="FormInput"
                  type="text"
                  id="username"
                  value={this.state.username}
                  onChange={this.handleUsernameChange}
                  onKeyPress={this.handleLoginKeyPress}
                  name="username"
                  placeholder={this.trans("username")}
                />
                <label>{this.trans("password")}</label>
                <input
                  className="FormInput"
                  type="password"
                  id="password"
                  value={this.state.password}
                  onChange={this.handlePasswordChange}
                  onKeyPress={this.handleLoginKeyPress}
                  name="password"
                  placeholder={this.trans("password")}
                />
                <div className="Button" onClick={this.handleInput}>
                  {this.trans("login")}
                </div>
              </div>
            ) : this.state.action == "Signup" ? (
              <div className="LandingPageLoginBody">
                <label>{this.trans("full_name")}</label>
                <input
                  className="FormInput"
                  type="text"
                  id="fullname"
                  value={this.state.fullname}
                  onChange={this.handleFullNameChange}
                  onKeyPress={this.handleSignupKeyPress}
                  name="fullname"
                  placeholder={this.trans("full_name")}
                />
                <label>{this.trans("phone_number")}</label>
                <input
                  className="FormInput"
                  type="text"
                  id="phone_number"
                  value={this.state.phoneNumber}
                  onChange={this.handlePhoneNumberChange}
                  onKeyPress={this.handleSignupKeyPress}
                  name="phone_number"
                  placeholder={this.trans("phone_number")}
                />
                <div className="Button" onClick={this.createLead}>
                  {this.trans("continue")}
                </div>
              </div>
            ) : null}
          </div>
        </div>
        <div className="LandingPageDrawingSpacer">
          {this.state.error ? <p>{this.state.error}</p> : null}
        </div>
        <div className="DrawingContainer">
          <div className="IconsContainer">
            {["Grass", "Weighing", "Environment"].map((x, i) => (
              <a
                href={"#" + x}
                key={i}
                className={
                  "Icon " +
                  x +
                  (self.state.selected == x ? " Selected" : "") +
                  (self.state.enabled.includes(x) ? " Enabled" : "")
                }
                style={{
                  opacity: [x, null].includes(self.state.selected) ? 1 : 0.3
                  // pointerEvents: [x, null].includes(self.state.selected)
                  //   ? "all"
                  //   // : "none"
                }}
              >
                {i % 2 == 1 ? <div /> : null}
                <h1>{x}</h1>
              </a>
            ))}
          </div>
          <img className="Drawing" src={Drawing} alt="Logo" />
        </div>
        <div className="LandingPageDrawingSpacer" />
        <div id="Grass" className="LandingPagePackage Grass">
          <div className="LandingPagePackageHeader">
            <div>
              <h1>{this.trans('grass')}</h1>
              <h2>{this.trans('grass_management_system')}</h2>
            </div>
            <div>
              <img className="FieldIcon" src={Field} alt="Logo" />
            </div>
          </div>
          <div className="LandingPagePackageBody">
            <ul>
              <li>{this.trans('map_your_farm')}</li>
              <li>{this.trans('grass_wedge')}</li>
              <li>{this.trans('avg_farm_cover')}</li>
              <li>{this.trans('manage_paddocks')}</li>
              <li>{this.trans('weekly_reports')}</li>
              <li>{this.trans('rotation_planning')}</li>
              <li>{this.trans('derogation_compliance')}</li>
            </ul>
            <img
              className="LandingPageScreenshot"
              src={Grass}
              alt="Grass Dashboard"
            />
          </div>
        </div>
        <div id="Weighing" className="LandingPagePackage Weighing">
          <div className="LandingPagePackageHeader">
            <div>
              <h1>{this.trans('weighing')}</h1>
              <h2>{this.trans('livestock_weighing_system')}</h2>
            </div>
            <div>
              <img className="FieldIcon" src={Field} alt="Logo" />
            </div>
          </div>
          <div className="LandingPagePackageBody">
            <ul>
              <li>{this.trans('real_time_results')}</li>
              <li>{this.trans('paperless_recording')}</li>
              <li>{this.trans('avg_daily_gain')}</li>
              <li>{this.trans('body_condition_scoring')}</li>
              <li>{this.trans('aims_icbf_sync')}</li>
              <li>{this.trans('eid_input')}</li>
              <li>{this.trans('drafting_output')}</li>
            </ul>
            <img
              className="LandingPageScreenshot"
              src={Weighing}
              alt="Weighing Dashboard"
            />
          </div>
        </div>
        <div id="Environment" className="LandingPagePackage Environment">
          <div className="LandingPagePackageHeader">
            <div>
              <h1>{this.trans('environment')}</h1>
              <h2>{this.trans('live_data_from_your_farm')}</h2>
            </div>
            <div>
              <img className="FieldIcon" src={Field} alt="Logo" />
            </div>
          </div>
          <div className="LandingPagePackageBody">
            <ul>
              <li>{this.trans('soil_temperature')}</li>
              <li>{this.trans('soil_moisture')}</li>
              <li>{this.trans('ambient_temperature')}</li>
              <li>{this.trans('predict_grass_growth')}</li>
              <li>{this.trans('met_data_integration')}</li>
              <li>{this.trans('moisture_deficit_alerts')}</li>
              <li>{this.trans('historical_data_review')}</li>
            </ul>
            <img
              className="LandingPageScreenshot"
              src={Environment}
              alt="Environment Dashboard"
            />
          </div>
        </div>
        <div className="LandingPageFooter">
          <span>
            {this.trans("copyright") + " "} © 2017 - {moment().format("YYYY")}{" "}
            True North Technologies Ltd.
          </span>
        </div>
      </div>
    );
  }
}

export default LandingPage;
